











import { Vue, Component, Watch } from 'vue-property-decorator'

@Component
export default class Menu extends Vue {
  public visible: boolean = this.$store.state.main.fromFlag === 'local_client'
  @Watch('$store.state.fromFlag') public watchFromFlag() {
    this.visible = this.$store.state.main.fromFlag === 'local_client'
  }
  public toLocal() {
    (window as any).toLocal()
  }
}
