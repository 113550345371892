
































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'

import {
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
  readHasAdminAccess,
} from '../../store/main/getters'
import {
  commitSetDashboardShowDrawer,
  commitSetDashboardMiniDrawer,
} from '../../store/main/mutations'
import { dispatchUserLogOut } from '../../store/main/actions'
import Localechanger from './other/LocaleProvider.vue'
import globalList from './other/GlobalListRanter.vue'
import svgWiDLogo from '../../components/svg_saves/home_header/WiDLogo.vue'
import { getRanterTreeData } from '../../interfaces/service/org/enterpriseMang'
import { getAvatarUserData, getImageByPerson } from '../../interfaces/service/person/userAuthority'
import svgHome from '../../components/svg_saves/main/Home.vue'
import svgUser from '../../components/svg_saves/home_header/User.vue'
import svgApp from '../../components/svg_saves/main/App.vue'
import svgMap from '../../components/svg_saves/main/Map.vue'
import svgSummary from '../../components/svg_saves/main/Summary.vue'
import svgTable from '../../components/svg_saves/main/Table.vue'
import svgStat from '../../components/svg_saves/main/Stat.vue'
import svgCompany from '../../components/svg_saves/main/Company.vue'
import newRanterModel from './enterprise/NewRanterModel.vue'
import { getBase64 } from '../../interfaces/service/person/personMang'
import _ from 'lodash'
import { readRanterId } from '@/store/main/getters'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { oidcAccount } from '@/interfaces/service/user/mainPage'
import LocalMode from '../../components/ChangeLocal/LocalMode.vue'

const routeGuardMain = async (to, from, next) => {
  if (to.path === '/main') {
    next('/main/dashboard')
  } else {
    next()
  }
}
let guidePageKeys = ''
@Component({
  components: {
    'locale-changer': Localechanger,
    globalList,
    svgWiDLogo,
    svgHome,
    svgUser,
    svgApp,
    svgMap,
    svgSummary,
    svgTable,
    svgStat,
    svgCompany,
    newRanterModel,
    LocalMode
  },
  data() {
    getRanterTreeData(this.$store.state.main.userProfile.user_type)
    return {
      collapsed: false,
      visible: false,
      authorityORG: '',
      authorityDevice: '',
      authorityAdmin: '',
      svgApp,
      svgMap,
      svgSummary,
      svgTable,
      svgStat,
      svgUser,
      svgCompany,
      newRanterModel,
    }
  },
  computed: {
    tagsData: {
      get() {
        return this.$store.state.main.headTagsave
      },
    },
    username: {
      get() {
        return (
          // this.$store.state.main.userProfile.first_name +
          // this.$store.state.main.userProfile.last_name
          this.$store.state.main.userProfile.login
        )
      },
    },
  },
})
export default class Main extends Vue {
  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store)
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store)
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value)
  }

  get hasAdminAccess() {
    return readHasAdminAccess(this.$store)
  }

  get antLocale() {
    return this.$store.state.main.antLocale || zhCN
  }

  get rantersData() {
     const ranterDatas =
       _.mapKeys(this.$store.state.main.enterprise.enterpriseData, (value, key) => {
        return value.id
      })
     return ranterDatas
  }

  /**
   * 租户ID
   */
  public get ranterId() : number {
    let id: number = readRanterId(this.$store)
    if (id === -10) { id = +(sessionStorage.getItem('ranterId') || -10) }
    return id
  }

  private get enterpriseHashData(): any {
    let hash = this.$store.state.main.enterprise.enterpriseHashData

    if (_.isEmpty(hash)) {
      hash = JSON.parse(sessionStorage.getItem('getEnterpriseHashData') || '{}')
    }

    return hash
  }

  private set enterpriseHashData(x: any) {
    console.log(x)
  }

  public guiderKey: string = guidePageKeys
  public closeAccess: boolean = true // 关闭租户选择
  public theWindowW = document.body.clientWidth
  public collapsed: boolean = false
  // 租户全局pullup菜单
  public ranterNavShow: boolean = false
  public addRanterModelFlag: boolean = false // 添加租户
  public ranterName: string = '' // 租户名称
  public ranterAvatarUrl: any = '' // 租户头像
  public isBottomNavRight: boolean = false // 底部导航条是否处于右侧
  public editConformFlag : boolean = false // tableView 存在修改的数据没保存时提示
  @Watch('$store.state.main.screen.width') public windowHeight() {
    this.theWindowW = document.body.clientWidth
  }
  @Watch('$store.state.main.pagePublic.ranterName')
  public ranterNameUpdated(value) {
    this.ranterName = localStorage.getItem('ranterNameChose') || value
  }
  @Watch('$store.state.main.pagePublic.ranterAvatarUrl')
  public ranterAvatarUrlUpdated(value) {
    this.ranterAvatarUrl = localStorage.getItem('ranterAvatarUrlChose') || value
    this.getRanterAvatar(this.ranterId, this.ranterAvatarUrl)
  }

  @Watch('$store.state.main.pagePublic.isNoJumping')
  public isNoJumpingUpdated() {
    const isNoJumping = this.$store.state.main.pagePublic.isNoJumping

    if (isNoJumping) { return }

    const lastActionType = this.$store.state.main.pagePublic.lastActionType
    const actionName = (lastActionType.splice(0, 1))[0]

    if (!actionName) { return }

    this[actionName](...lastActionType)
  }
  public beforeRouteEnter(to, from, next) {
    // TODO 后期有其他页面进入时复用
    switch (from.path) {
      case '/home-header/home':
        guidePageKeys = 'data'
        localStorage.setItem('getGuider', 'data')
        break
      default:
        guidePageKeys = localStorage.getItem('getGuider') || 'data'
        break
    }
    routeGuardMain(to, from, next)
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next)
  }

  public guildNavchanged(toGuildKey, path) {
    if (this.$store.state.main.pagePublic.isNoJumping) {
      this.$store.commit('setLastActionType', ['guildNavchanged', toGuildKey, path])
      return
    }

    if (this.$store.state.main.userAuthorityShow.hadEditFlag) {
      this.editConformFlag = true
    } else {
      if (this.guiderKey !== toGuildKey) {
        this.$router.push({
          path,
          query: { ranterName: localStorage.getItem('ranterNameChose') || this.ranterName }
        })
      }
      this.guiderKey = toGuildKey
      localStorage.setItem('getGuider', toGuildKey)
    }
  }
  public switchShowDrawer() {
    commitSetDashboardShowDrawer(this.$store, !readDashboardShowDrawer(this.$store))
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(this.$store, !readDashboardMiniDrawer(this.$store))
  }

  public toAccount() {
    oidcAccount()
  }

  public async logout() {
    setTimeout(() => {
      if (this.$store.state.main.pagePublic.isNoJumping) {
        this.$store.commit('setLastActionType', ['logout'])
        return
      }

      if (this.$store.state.main.userAuthorityShow.hadEditFlag) {
        this.editConformFlag = true
      } else {
        dispatchUserLogOut(this.$store)
      }
    }, 100)
  }
  public onCollapse(cflag, type) {
    this.collapsed = !this.collapsed
    cflag = this.collapsed
  }

  public navChanger(page) {
    setTimeout(() => {
      if (this.$store.state.main.pagePublic.isNoJumping) {
        this.$store.commit('setLastActionType', ['navChanger', page])
        return
      }

      if (this.$store.state.main.userAuthorityShow.hadEditFlag) {
        this.editConformFlag = true
      } else {
        this.$router.push({
          path: page,
          query: { ranterName: localStorage.getItem('ranterNameChose') || this.ranterName }
        })
      }
    }, 100)
  }

  // public handleTag(tagDataName, tagDataParam) {
  //   this.$router.push({ name: tagDataName, params: tagDataParam }).catch((err) => {
  //     // console.log(err)
  //   })
  // }

  public backHome() {
    setTimeout(() => {
      if (this.$store.state.main.pagePublic.isNoJumping) {
        this.$store.commit('setLastActionType', ['backHome'])
        return
      }

      if (this.$store.state.main.userAuthorityShow.hadEditFlag) {
        this.editConformFlag = true
      } else {
        this.$router.push({ path: '/home-header/home' })
      }
    }, 100)
  }
  public editConformOk() {
    this.editConformFlag = false
  }
  public ranterNavShowFunc() {
    setTimeout(() => {
      // 管理表格是处于编辑状态并且存在修改 -- 不允许跳转
      if (this.$store.state.main.pagePublic.isNoJumping) {
        this.$store.commit('setLastActionType', ['ranterNavShowFunc'])
        return
      }

      if (this.$store.state.main.userAuthorityShow.hadEditFlag) {
        this.editConformFlag = true
      } else {
        this.closeAccess = false
        this.ranterNavShow = !this.ranterNavShow
        setTimeout(() => {
          this.closeAccess = true
        }, 300)
      }
    }, 100)
  }
  /**切换租户回调
   * @param {any} ranterData 租户信息
   */
  public ranterChange(ranterData) {
    if (ranterData.addRanter === 'show') {
      this.addRanterModelFlag = true
      this.closeAccess = false
    }
    if (ranterData.ranterName) {
      this.ranterName = ranterData.ranterName
    }
    if (ranterData.ranterNavShow === 'off') {
      this.ranterNavShow = false // 关闭显示
    }
    if (ranterData.index !== -1) {
      const ranterID = ranterData.ranterID
      this.$store.commit('setRanterId', ranterID)
      sessionStorage.setItem('ranterId', ranterID)
      let toPath = ''
      switch (this.guiderKey) {
        case 'data':
          getAvatarUserData(this.$store.state.main.userProfile.user_type, ranterID)
          toPath = '/main/guider-nav-page/tables/person'
          break
        case 'stat':
          toPath = '/main/guider-nav-page/statistics/date'
          break
        case 'summary':
          toPath = '/main/guider-nav-page/summary'
          break
      }
      this.$router
        .push({
          path: toPath,
          query: { ranterName: this.ranterName }
        }) // TODO重复跳转页面
      localStorage.setItem('ranterNameChose', this.ranterName)
      this.$store.commit('setRanterName', this.ranterName)
      localStorage.setItem('childOrgChoseStore', '')
      this.$store.commit('setExpandedKeys', [])
      sessionStorage.setItem('expandedKeys', '[]')
      this.$store.commit('setSelectedKeys', [])
      localStorage.setItem('selectedKeys', '[]')
      // 获取租户头像
      this.ranterAvatarUrl = this.enterpriseHashData[ranterID] ? this.enterpriseHashData[ranterID].avatar :
      ''
      this.getRanterAvatar(ranterID, this.ranterAvatarUrl)
    }
  }
  public newRanterClose() {
    this.addRanterModelFlag = false
    setTimeout(() => {
      this.closeAccess = true
    }, 300)
  }
  // 租户全局pullup菜单 END

  /**
   * 底部导航栏移动
   */
  public bottomNavMove() {
    this.isBottomNavRight = !this.isBottomNavRight
  }

  /**
   * 获取租户头像
   * @param {number} ranterId 租户id
   * @param {url} string 该租户的头像url地址
   */
  private async getRanterAvatar(ranterId, url) {
    if (!url) {
      this.ranterAvatarUrl = ''
      return
    }
    await getImageByPerson(ranterId, url)
    this.ranterAvatarUrl = await getBase64(this.$store.state.main.messagePro)
  }

  private async mounted() {
    const ranterName: any = this.$router.currentRoute.query.ranterName || ''
    this.ranterName = ranterName
    if (this.ranterName) {
      localStorage.setItem('ranterNameChose', this.ranterName)
      this.$store.commit('setRanterName', this.ranterName)
    }
    // 获取用户头像url
    this.ranterAvatarUrl = this.enterpriseHashData[this.ranterId]?.avatar
    // 获取租户头像
    this.getRanterAvatar(this.ranterId, this.ranterAvatarUrl)
  }
}
