





















































import { Vue, Component } from 'vue-property-decorator'
import {
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
  readHasAdminAccess,
} from '../../store/main/getters'
import {
  commitSetDashboardShowDrawer,
  commitSetDashboardMiniDrawer,
} from '../../store/main/mutations'
import { dispatchUserLogOut } from '../../store/main/actions'
import Localechanger from './other/LocaleProvider.vue'
import svgWiDLogo from '../../components/svg_saves/home_header/WiDLogo.vue'
import svgUser from '../../components/svg_saves/home_header/User.vue'
import LocalMode from '../../components/ChangeLocal/LocalMode.vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { oidcAccount } from '@/interfaces/service/user/mainPage'
const routeGuardMain = async (to, from, next) => {
  if (to.path === '/main') {
    next('/main/dashboard')
  } else {
    next()
  }
}

@Component({
  components: {
    'locale-changer': Localechanger,
    svgWiDLogo,
    svgUser,
    LocalMode
  },
  data() {
    return {
      collapsed: false,
      visible: false,
      authorityORG: '',
      authorityDevice: '',
      authorityAdmin: '',
    }
  },
  computed: {
    tagsData: {
      get() {
        return this.$store.state.main.headTagsave
      },
    },
    username: {
      get() {
        return (
          // this.$store.state.main.userProfile.first_name +
          // this.$store.state.main.userProfile.last_name
          this.$store.state.main.userProfile.login
        )
      },
    },
  },
})
export default class HomeHeader extends Vue {
  public collapsed: boolean = false

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next)
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next)
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store)
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store)
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value)
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store)
    )
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store)
    )
  }

  get hasAdminAccess() {
    return readHasAdminAccess(this.$store)
  }

  public async logout() {
    dispatchUserLogOut(this.$store)
  }

  public async toAccount() {
    oidcAccount()
  }

  get antLocale() {
    return this.$store.state.main.antLocale  || zhCN
  }
  public onCollapse(cflag, type) {
    this.collapsed = !this.collapsed
    cflag = this.collapsed
  }

  public navChanger(page) {
    this.$router.push({ path: page })
  }

  public handleTag(tagDataName, tagDataParam) {
    this.$router
      .push({ name: tagDataName, params: tagDataParam })
  }
}
