import { apiUrl } from '@/env'
import { requestToken } from '../../../api/manage'

// 获取登陆者的Token信息
export function getToken(username, password) {
  const params = new URLSearchParams()
  params.append('username', username)
  params.append('password', password)
  return requestToken({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: '/api/v1/login/access-token',
    method: 'post',
    data: params,
  })
}

// 登陆的user信息获取
export function getUserMe() {
  return requestToken({
    url: '/api/v1/users/me',
    method: 'get',
  })
}

// 登陆oidc
export function oidcLogin() {
  location.href = apiUrl + '/api/v1/oidc_login'
}

// account页面
export function oidcAccount() {
  location.href = apiUrl + '/api/v1/oidc_account?homeUrl=' + encodeURIComponent(window.location.href)
  // location.href = 'http://localhost:8080/auth/realms/wid/account?homeUrl=' + encodeURIComponent(window.location.href)
}

