







































import { Component, Vue } from 'vue-property-decorator'
import enUS from 'ant-design-vue/lib/locale-provider/en_US'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import jaJP from 'ant-design-vue/lib/locale-provider/ja_JP'
import moment from 'moment'
import 'moment/locale/zh-cn'
import 'moment/locale/ja'

moment.locale('zh-cn')
@Component
export default class Localechanger extends Vue {
  public localTitleShow: string = 'CN'
  public EN = 'en-us'
  public ZH = 'zh-cn'
  public JP = 'ja-jp'
  public data() {
    return {
      antLocale: null,
    }
  }

  public changeLocale(e) {
    const localeValue = e
    if (localeValue === this.EN) {
      this.localTitleShow = 'EN'
      moment.locale(this.EN)
      localStorage.setItem('locale', this.EN)
      // 语言选项缓存,刷新/重新进入页面，语言依旧生效
      this.$i18n.locale = localStorage.getItem('locale') || ''
      this.$store.state.main.antLocale = enUS
    }
    if (localeValue === this.ZH) {
      this.localTitleShow = 'CN'
      moment.locale(this.ZH)
      localStorage.setItem('locale', this.ZH)
      this.$i18n.locale = localStorage.getItem('locale') || ''
      this.$store.state.main.antLocale = zhCN
    }
    if (localeValue === this.JP) {
      this.localTitleShow = 'JP'
      moment.locale(this.JP)
      localStorage.setItem('locale', this.JP)
      this.$i18n.locale = localStorage.getItem('locale') || ''
      this.$store.state.main.antLocale = jaJP
    }
    this.$store.state.main.navChoce = 'home'
  }
  private mounted() {
    this.$nextTick(function() {
      switch (localStorage.getItem('locale')) {
        case 'en-us':
          this.$store.state.main.antLocale = null
          this.$i18n.locale = 'en-us'
          this.localTitleShow = 'EN'
          break
        case 'zh-cn':
          this.$store.state.main.antLocale = zhCN
          this.$i18n.locale = 'zh-cn'
          this.localTitleShow = 'CN'
          break
        case 'ja-jp':
          this.$store.state.main.antLocale = jaJP
          this.$i18n.locale = 'ja-jp'
          this.localTitleShow = 'JP'
          break
        default:
          this.$store.state.main.antLocale = zhCN
          this.$i18n.locale = 'zh-cn'
          this.localTitleShow = 'CN'
          break
      }
      return this.$store.state.main.antLocale
    })
  }
}
